export default {
  metaInfo: {
    title: 'contact page',
  },
  name: 'contact',
  components: {
  },
  props: {},
  data: () => ({

  }),
  computed: {
    infoEmail() {
      return process.env.VUE_APP_INFO_EMAIL_ADDRESS;
    },
  },
  methods: {},
};
